@use "../../public/styles/variables.scss";

#mainMenu.desktop {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  margin: 0;

  .menuBlock {
    display: flex;
    margin-top: 4px;

    .menuItem {
      @include variables.P3;
      margin-right: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: variables.$ui_01;

      &.active {
        color: variables.$warning_01 !important;
      }

      .menuTitle {
        position: relative;
      }

      .subMenu {
        display: none;
        position: absolute;
        flex-direction: column;
        background: variables.$ui_01;
        box-shadow: 0px 14px 24px rgba(88, 98, 152, 0.2);
        border-radius: 4px;
        color: variables.$text_02;
        padding: 8px 0;
        top: 50px;

        .menuItem {
          padding: 4px 16px;
          margin: 0;
          color: variables.$text_02;
          text-decoration: none;

          &:hover {
            background-color: variables.$ui_02;
          }
        }
      }

      &:hover {
        .subMenu {
          display: flex;
        }

        &.withSubmenu::after {
          transform: rotate(-90deg);
        }
      }

      &.withSubmenu::after {
        content: ' ';
        contain: content;
        width: 8px;
        height: 8px;
        background-image: url(~/src/assets/images/bracket.svg);
        background-repeat: no-repeat;
        background-position: center center;
        transform: rotate(90deg);
        margin-left: 4px;
        transition: transform 0.2s ease-in-out;
      }
    }
  }

  .menuIcon {
    display: none;
  }


  .buttonBlock {
    display: flex;

    .menuButton {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 4px 12px;
    }
  }
}

.activeYotitle .top #mainMenu.desktop .menuItem .subMenu {
  top: 98px;
}

.top.white #mainMenu.desktop .menuItem.withSubmenu::after {
  background-image: url(~/src/assets/images/bracket_blue.svg);
}

#mainMenu.mobile {
  color: variables.$ui_01;
  margin: 7px 0;
  padding: 0;

  .menuIcon {
    width: 20px;
    height: 20px;
    background-image: url(~/src/assets/images/hamburger.svg);
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .menuBlock {
    display: none;
    overflow: auto;

    .menuItem {
      padding: 8px 0;
      display: block;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      color: variables.$ui_01;
      text-decoration: none;
      font-size: 20px;
      position: relative;
      margin-left: 20px;

      &.active {
        color: variables.$warning_01 !important;
      }

      .subMenu {
        display: none;
        display: block;
        color: variables.$warning_01;
        margin-top: 8px;
        display: none;

        .menuItem {
          text-decoration: none;
        }
      }

      &.withSubmenu::before {
        contain: content;
        content: " ";
        color: variables.$ui_01;
        position: absolute;
        top: 15px;
        left: -20px;
        width: 16px;
        height: 16px;
        background-image: url(~/src/assets/images/bracket.svg);
        background-repeat: no-repeat;
        background-position: center center;
      }

      &.active {
        position: relative;

        .subMenu {
          display: block;
        }
        
        &.withSubmenu::before {
          transform: rotate(90deg);
        }
      }
    }

  }

  .buttonBlock {
    display: none;

    >div {
      width: 100%;
      text-align: center;

      a {
        width: 256px;
        margin-right: 0px;
        padding: 8px 16px;
        font-size: 16px;
        line-height: 24px;
      }

      &:first-child {
        display: none;
      }

      &:last-child {
        padding-left: 8px;
      }
    }
  }

  &.active {
    background-color: variables.$neutral_01;
    position: fixed;
    inset: -16px 0 -16px;
    padding: 16px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .menuIcon {
      align-self: end;
      position: absolute;
      top: 35px;
      right: 25px;
      background-image: none;

      svg {
        fill: variables.$ui_01;
        background-color: variables.$neutral_01;
      }
    }

    .menuBlock {
      display: block;
      flex-basis: 100%;
      margin-top: 49px;
    }

    .buttonBlock {
      display: flex;
    }
  }
}

.top.white #mainMenu.mobile {
  .menuIcon {
    background-image: url(~/src/assets/images/hamburger_blue.svg);
  }

  &.active .menuIcon {
    background-image: url(~/src/assets/images/close.svg);
  }
}

.logo a {
  display: flex;
  align-items: center;
  gap: 8px;

  .product {
    display: none;

    &.active {
      display: flex;
      align-items: center;
    }
  }
}