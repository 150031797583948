#mainMenu.desktop {
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  display: flex;
}

#mainMenu.desktop .menuBlock {
  margin-top: 4px;
  display: flex;
}

#mainMenu.desktop .menuBlock .menuItem {
  cursor: pointer;
  color: #fff;
  align-items: center;
  margin-right: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  display: flex;
}

#mainMenu.desktop .menuBlock .menuItem.active {
  color: #f5ab01 !important;
}

#mainMenu.desktop .menuBlock .menuItem .menuTitle {
  position: relative;
}

#mainMenu.desktop .menuBlock .menuItem .subMenu {
  color: #505565;
  background: #fff;
  border-radius: 4px;
  flex-direction: column;
  padding: 8px 0;
  display: none;
  position: absolute;
  top: 50px;
  box-shadow: 0 14px 24px #58629833;
}

#mainMenu.desktop .menuBlock .menuItem .subMenu .menuItem {
  color: #505565;
  margin: 0;
  padding: 4px 16px;
  text-decoration: none;
}

#mainMenu.desktop .menuBlock .menuItem .subMenu .menuItem:hover {
  background-color: #eceef3;
}

#mainMenu.desktop .menuBlock .menuItem:hover .subMenu {
  display: flex;
}

#mainMenu.desktop .menuBlock .menuItem:hover.withSubmenu:after {
  transform: rotate(-90deg);
}

#mainMenu.desktop .menuBlock .menuItem.withSubmenu:after {
  content: " ";
  contain: content;
  background-image: url("../images/bracket.e260c2a1.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 8px;
  height: 8px;
  margin-left: 4px;
  transition: transform .2s ease-in-out;
  transform: rotate(90deg);
}

#mainMenu.desktop .menuIcon {
  display: none;
}

#mainMenu.desktop .buttonBlock {
  display: flex;
}

#mainMenu.desktop .buttonBlock .menuButton {
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.activeYotitle .top #mainMenu.desktop .menuItem .subMenu {
  top: 98px;
}

.top.white #mainMenu.desktop .menuItem.withSubmenu:after {
  background-image: url("../images/bracket_blue.a1d1ae31.svg");
}

#mainMenu.mobile {
  color: #fff;
  margin: 7px 0;
  padding: 0;
}

#mainMenu.mobile .menuIcon {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-image: url("../images/hamburger.9281e3c2.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

#mainMenu.mobile .menuBlock {
  display: none;
  overflow: auto;
}

#mainMenu.mobile .menuBlock .menuItem {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  color: #fff;
  margin-left: 20px;
  padding: 8px 0;
  font-size: 20px;
  text-decoration: none;
  display: block;
  position: relative;
}

#mainMenu.mobile .menuBlock .menuItem.active {
  color: #f5ab01 !important;
}

#mainMenu.mobile .menuBlock .menuItem .subMenu {
  color: #f5ab01;
  margin-top: 8px;
  display: none;
}

#mainMenu.mobile .menuBlock .menuItem .subMenu .menuItem {
  text-decoration: none;
}

#mainMenu.mobile .menuBlock .menuItem.withSubmenu:before {
  contain: content;
  content: " ";
  color: #fff;
  background-image: url("../images/bracket.e260c2a1.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 15px;
  left: -20px;
}

#mainMenu.mobile .menuBlock .menuItem.active {
  position: relative;
}

#mainMenu.mobile .menuBlock .menuItem.active .subMenu {
  display: block;
}

#mainMenu.mobile .menuBlock .menuItem.active.withSubmenu:before {
  transform: rotate(90deg);
}

#mainMenu.mobile .buttonBlock {
  display: none;
}

#mainMenu.mobile .buttonBlock > div {
  text-align: center;
  width: 100%;
}

#mainMenu.mobile .buttonBlock > div a {
  width: 256px;
  margin-right: 0;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
}

#mainMenu.mobile .buttonBlock > div:first-child {
  display: none;
}

#mainMenu.mobile .buttonBlock > div:last-child {
  padding-left: 8px;
}

#mainMenu.mobile.active {
  background-color: #536ced;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  display: flex;
  position: fixed;
  inset: -16px 0;
  overflow: hidden;
}

#mainMenu.mobile.active .menuIcon {
  background-image: none;
  align-self: end;
  position: absolute;
  top: 35px;
  right: 25px;
}

#mainMenu.mobile.active .menuIcon svg {
  fill: #fff;
  background-color: #536ced;
}

#mainMenu.mobile.active .menuBlock {
  flex-basis: 100%;
  margin-top: 49px;
  display: block;
}

#mainMenu.mobile.active .buttonBlock {
  display: flex;
}

.top.white #mainMenu.mobile .menuIcon {
  background-image: url("../images/hamburger_blue.1c42fd9f.svg");
}

.top.white #mainMenu.mobile.active .menuIcon {
  background-image: url("../images/close.80b0ee15.svg");
}

.logo a {
  align-items: center;
  gap: 8px;
  display: flex;
}

.logo a .product {
  display: none;
}

.logo a .product.active {
  align-items: center;
  display: flex;
}
/*# sourceMappingURL=menu.0747fecf.css.map */
